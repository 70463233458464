import { ChangepasswordGuard } from './core/guards/changepassword.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './pages/_layout/layout.component';
import { ContentGuard } from './core/guards/content.guard';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./pages/add-admin/add-admin.module').then(
                (m) => m.AddAdminModule
            ),
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, ChangepasswordGuard],
        children: [
            {
                path: 'insurance-companies',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/insurance-companies/insurance-companies.module').then(
                        (m) => m.InsuranceCompaniesModule
                    ),
            },
            {
                path: 'najm-elm',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/najm-elm/najm-elm.module').then(
                        (m) => m.NajmElmModule
                    ),
            },
            {
                path: 'hyper-pay',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/hyper-pay/hyper-pay.module').then(
                        (m) => m.HyperPayModule
                    ),
            },
            {
                path: 'finance-report',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/finance-reports/finance-reports.module').then(
                        (m) => m.FinanceReportsModule
                    ),
            },
            {
                path: 'policies-report',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/policies-report/policies-reports.module').then(
                        (m) => m.PoliciesReportsModule
                    ),
            },
            {
                path: 'user-checkout',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/user-checkout/user-checkout.module').then(
                        (m) => m.UserCheckoutModule
                    ),
            },
            // {
            //     path: 'settings',
            //     // canActivate: [ContentGuard],
            //     // data: { permission: 'TechAdmin' },
            //     loadChildren: () =>
            //         import('./pages/discount-settings/discount.settings.module').then(
            //             (m) => m.DiscountSettingsModule
            //         ),
            // },
            {
                path: 'company-settings',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/company-setting/company-setting.module').then(
                        (m) => m.CompanySettingModule
                    ),
            },
            {
                path: 'delete-nin',
                // canActivate: [ContentGuard],
                // data: { permission: 'EqlhaAdminOnly' },
                loadChildren: () =>
                    import('./pages/deletenin/deletenin.module').then(
                        (m) => m.DeleteninModule
                    )
            },
            {
                path: 'add-admin',
                canActivate: [ContentGuard],
                data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import('./pages/add-admin/add-admin.module').then(
                        (m) => m.AddAdminModule
                    ),
            },
            {
                path: 'fail-policy',
                loadChildren: () =>
                    import('./pages/fail-policy/fail-policy.module').then(
                        (m) => m.FailPolicyModule
                    ),
            },
            {
                path: '',
                redirectTo: 'insurance-companies',
                pathMatch: 'full',
            },
            {
                path: 'policies-statistics',
                // canActivate: [ContentGuard],
                // data: { permission: 'ClientAdmin' },
                loadChildren: () =>
                    import(
                        './pages/statistics/policies-statistics/policies-statistics.module'
                    ).then((m) => m.PoliciesStatisticsModule),
            }
        ],
    },
    { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
];


const config: ExtraOptions = {
    useHash: false,
    initialNavigation: !!localStorage.getItem('access_token'),
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
